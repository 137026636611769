// Bootstrap incorrect directory 
//https://stackoverflow.com/questions/56791106/issues-with-not-finding-glyphicons-or-aliases-during-building
$bootstrap-sass-asset-helper: false !default;
$icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../../fonts/bootstrap/") !default;

@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import 'vars';
@import 'layout';
@import 'buttons';
@import 'header';
@import 'footer';
@import 'banner';
@import 'bootstrap-toggle';
@import 'shareLinks';

/* Page specific styles */
@import 'pages/admin_panel';
@import 'pages/welcome';
@import 'pages/jmr';
@import 'pages/assessment_pdfs';

body {
	font-size: $base-font-size;
	font-family: $base-font-family;
	background-color: $background-color;
}

h1, .h1 {
	font-size: 2em;
}

h2, .h2 {
	font-size: 1.8em;
}

h3, .h3 {
	font-size: 1.4em;
}

h4, .h4 {
	font-size: 1.2em;
}

h5, .h5 {
	font-size: 1.0em;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: bold;
	line-height: 1.1em;
}

.toggle-img {
  display: inline;
  height:16px;
  padding-right: 5px;
}

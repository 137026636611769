div.container[role="main"] > div.row {
  padding-top: 2em;

  > .content {
    @extend .col-md-8;

    &.content-full-width {
      @extend .col-md-12;
    }

    div.page-subheader {
      text-align: center;
      padding-bottom: 2em;

      > h2 {
        font-weight: bold;
      }
    }
  }

  > .sidebar {
    @extend .col-md-4;
    .nav-pills {
      li {
        margin-left: -15px;
        &.title {
          margin-left: 0;
          margin-bottom: 10px;

          p {
            font-weight: bold;
          }
        }
        &.last {
          margin-top: 60px;
          @media only screen and (max-width: 768px) {
            margin: 40px 0 40px -15px;
          }
        }
      }

      hr {
        margin: 20px 0 30px 0;
      }
    }
  }
}

#discovery_job_bank + p.help-block {
  display: inline;
}

button.btn.ability-table-btn {
  background-color: transparent;
  color: #333333;
}

table.war-ability-table > tr > td {
  vertical-align: middle;
}

.Measurable-skills-over-time-scores {
  margin-top: 50px;
}
canvas.waot-line-chart {
  margin-top: 50px;
}

div.rar-ability-scores {
  table.rar-ability-table {
    tr {
      cursor: pointer;
    }
  }
}

table.waot-ability-table {
  tr {
    cursor: pointer;
  }
}

.d-flex {
  display: flex;
}

.mr-auto {
  margin-right: auto;
}

.mr-2 {
  margin-right: 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

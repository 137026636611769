footer.footer {
	margin-top: 50px;

	.container {

		padding-top: 30px;
		border-top: 1px solid $secondary-color;

		.sponsor-logos {
			@extend .col-md-9;
			text-align: right;
			img {
				padding: 10px;
			}
		}

		.copyright {
			@extend .col-md-3;
			text-align: right;
			padding-top: 20px;
		}
	}
}

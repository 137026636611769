.btn {
	color: $button-text-color;
	border: 0;
	padding: 10px 20px;

	&.btn-primary {
		background: linear-gradient(0deg, darken($primary-color, 10%), $primary-color);
	}

	&.btn-default {
		background: linear-gradient(0deg, darken($secondary-color, 10%), $secondary-color);
	}

	&.form-control[type=submit] {
		width: auto;
		height: auto;
	}
}

.back-to-org-btn {
	margin-bottom: 20px;
}

.archive-user-btn {
	margin-bottom: 15px;
}
div.main-banner {
  @extend .row;

  text-align: center;
  background-color: $primary-banner-color;
  background-size: cover;
  background-position: center;
  padding: 5em 0;
  color: $background-color;

  &.background-home {
    background: url(img/park_walk.jpg);
    background-position: center bottom;
    background-size: cover;
  }

  .page-header {
    color: $main-header-text-color;
    text-transform: uppercase;
    border: 0;
    padding-bottom: 0;

    h1 {
      font-weight: bold;
      font-size: 3em;
    }
  }

  .welcome-login-form {
    @extend .col-md-4;
    text-align: center;
    margin: 0 auto;
    float: none;
  }
}

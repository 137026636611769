.jmr-pie {
	page-break-before: always;
}

.vocational-fingerprint-panel {
	page-break-before: always;
}

.download-report-link {
	text-align: right;
}

.panel-score-1 {
	@include panel-variant($score-1-color, black, $score-1-color, $score-1-color);
}

.panel-score-2 {
	@include panel-variant($score-2-color, black, $score-2-color, $score-2-color);
}

.panel-score-3 {
	@include panel-variant($score-3-color, black, $score-3-color, $score-3-color);
}

.panel-score-4 {
	@include panel-variant($score-4-color, black, $score-4-color, $score-4-color);
}

.panel-score-5 {
	@include panel-variant($score-5-color, black, $score-5-color, $score-5-color);
}

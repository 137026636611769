header nav.navbar-default {
  background-color: $background-color;
  border: 0;

  .navbar-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    min-height: 1px;
    > a.navbar-brand {
      height: auto;
      margin-right: auto;
      img {
        max-width: 275px;
      }
    }
  }

  .navbar-collapse > .navbar-nav {
    margin-top: 20px;

    > li {
      margin-top: 10px;

      > a.btn {
        color: $button-text-color;
        margin-right: 10px;
        padding: 10px 20px;
      }
    }
  }
}

.lang-select {
  list-style: none none;
  float: right;
  margin-right: 30px;
}

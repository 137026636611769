.share-link {
    margin-bottom: 40px;
    padding-top: calc(40px - 2em);
    padding-bottom: 40px;
    border-bottom: 1px solid lighten($secondary-color, 12%);
    .share-link-container {
        .share-link-inner {
            p {
                text-transform: uppercase;
            }
            #link-container {
                display: inline-block;
                border: 1px solid $primary-color;
                padding: 15px;
                @media only screen and (max-width: 768px) {
                    display: block;
                }
            }
            #copy-share {
                padding: 15px 25px;
                border-radius: 0;
                border: 1px solid $primary-color;
                border-left: none;
                margin-top: -2px;
                margin-left: -4px;
                @media only screen and (max-width: 768px) {
                    display: block;
                    margin: 0;
                }
            }
        }
    }
}
.copy-notification {
    color: #fff;
    padding: 20px 25px;
    border-radius: 30px;
    position: fixed;
    top: 93%;
    left: 87%;
    min-width: 150px;
    margin-top: -30px;
    margin-left: -85px;
    display: none;
    text-align: center;
    background: $score-4-color;
}
$primary-color: #cc0000;
$secondary-color: #cccccc;
$primary-banner-color: #77081B;

$logo-primary-color: $primary-color;
$logo-secondary-color: #666666;

$background-color: #ffffff;

$text-color: #000000;
$main-header-text-color: #ffffff;
$header-text-color: $text-color;

$button-primary-color: $primary-color;
$button-default-color: $secondary-color;
$button-text-color: $main-header-text-color;

$base-font-size: 14px;
$base-font-family: 'Open Sans', sans-serif;

$score-5-color: #00B050;
$score-4-color: #09d796;
$score-3-color: #FFFF00;
$score-2-color: #fb8140;
$score-1-color: #FF0000;
